import { Component, inject, Signal } from '@angular/core';
import { DestroyReferenceDirective } from '@boels-shared/directives/destroy-reference.directive';
import { UserStoreService } from '@boels-core/services/user.store.service';
import { Observable } from 'rxjs';
import { Debtor } from '@boels-core/models/user.model';
import { toSignal } from '@angular/core/rxjs-interop';
import { TrustHtmlPipe } from '../../pipes/trust-html.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipDirective } from '../../directives/tooltip.directive';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'boels-selected-debtor',
  templateUrl: './selected-debtor.component.html',
  styleUrl: './selected-debtor.component.scss',
  standalone: true,
  imports: [TooltipDirective, AsyncPipe, TranslateModule, TrustHtmlPipe],
})
export class SelectedDebtorComponent extends DestroyReferenceDirective {
  private readonly userStoreService = inject(UserStoreService);
  public accounts$: Observable<ReadonlyArray<Debtor>> = this.userStoreService.accounts$;
  public $activeAccounts: Signal<Array<Debtor>> = toSignal(this.userStoreService.activeAccounts$);
}
