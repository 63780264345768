import { Injectable } from '@angular/core';
import { ModalsService } from '@boels-shared/services/modals.service';
import { Observable, isObservable, lastValueFrom } from 'rxjs';

export interface ComponentWithDeactivation {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

/**
 * Guard that checks if a component can be deactivated.
 */

@Injectable({ providedIn: 'root' })
export class ComponentCanDeactivate {
  constructor(private readonly modalsService: ModalsService) {}

  /**
   * Checks if the component can be deactivated.
   * @param component - The component to be deactivated.
   * @returns A boolean indicating if the component can be deactivated.
   */

  public async canDeactivate(component: ComponentWithDeactivation): Promise<boolean> {
    if (!component.canDeactivate) return true;
    const canDeactivate = component.canDeactivate();
    const result = isObservable(canDeactivate)
      ? await lastValueFrom(canDeactivate)
      : await Promise.resolve(canDeactivate);
    if (result) {
      return true;
    }
    return this.modalsService.openDiscardModal();
  }
}
