import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GLOBAL_ID_LOADER } from '@boels-shared/constants/global.const';
import { Store } from '@ngxs/store';
import { NgProgress, NgProgressRef } from 'ngx-progressbar';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ToggleLoading } from '../../state/actions/shared.action';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private readonly progressRef: NgProgressRef;

  constructor(
    private readonly ngProgress: NgProgress,
    private readonly store: Store
  ) {
    this.progressRef = this.ngProgress.ref(GLOBAL_ID_LOADER);
  }

  public intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    // Starts the progress bar.
    this.progressRef?.start();
    // Updates shared store loading state.
    this.store.dispatch(new ToggleLoading(true));

    return next.handle(request).pipe(
      finalize(() => {
        // Ends the progress bar either with success or fail request.
        this.progressRef?.complete();
        // Updates shared store loading state.
        this.store.dispatch(new ToggleLoading(false));
      })
    );
  }
}
