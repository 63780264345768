@if (isMobile$ | async) {
  <app-mobile-navigation></app-mobile-navigation>
}

<main class="page-background">
  <boels-site-header>
    <ng-container SiteContextSlot>
      <boels-direct-hire
        [intro]="'cms-direct-hire.intro' | cmsContent"
        [phoneNumber]="'cms-direct-hire.phoneNumber' | cmsContent"
        [phoneSuffix]="'cms-direct-hire.phoneSuffix' | cmsContent"
        [countryCode]="localizationService.country$ | async"
      ></boels-direct-hire>
    </ng-container>
    <ng-container SiteLogoSlot>
      <a class="logo" [routerLink]="[appRoutesUrisConst.Dashboard()]">
        <img
          ngSrc="assets/images/boels-rental-logo.jpg"
          width="110"
          height="51"
          alt="Boels rental"
          class="logo"
          loading="lazy"
        />
      </a>
    </ng-container>
    <ng-container SiteLoginSlot>
      <app-debtor-selector></app-debtor-selector>
      <app-user-menu></app-user-menu>
    </ng-container>
    @if (!(isMobile$ | async)) {
      <div class="navigation-bar__slot-container" NavigationBarSlot>
        <app-navigation></app-navigation>
        <a
          class="btn btn-mini btn-secondary navigation-bar__new-order"
          href="{{ EXTERNAL_URL.BOELS.HIRE(localizationService.localization) }}"
          [qa]="'navigation-bar__new-order'"
          >{{ 'orders.startNewOrder' | translate }}</a
        >
      </div>
    }
  </boels-site-header>

  <div class="container__main">
    <router-outlet></router-outlet>
  </div>

  <footer class="content-grid">
    <div class="component-footer">
      <div></div>
      <div class="component-footer__version">Version {{ appVersion }}</div>
      <div class="component-footer__external-links external-links">
        <a href="{{ baseBoelsUrl }}/{{ this.localizationService.localization }}/disclaimer" target="_blank"
        >Disclaimer
        </a>
        /
        <a href="{{ baseBoelsUrl }}/{{ this.localizationService.localization }}/privacy-statement" target="_blank">
          Privacy statement</a
        >
      </div>
    </div>
  </footer>
</main>
