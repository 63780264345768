import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ImageLoaderService {
  public getImagePath(directoryPath: string, id: string): string {
    return `${directoryPath}${id.toUpperCase()}.png`;
  }
}
