import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class CredentialsInterceptor implements HttpInterceptor {
  constructor() {}

  public intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    if (request.method === 'JSONP' || request.headers.get('skip')) {
      return next.handle(request);
    }
    const headers = {
      client_id: environment.client_id,
    };
    const currentRequest = request;

    const nextRequest = currentRequest.clone({
      setHeaders: headers,
    });
    return next.handle(nextRequest);
  }
}
