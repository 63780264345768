import { inject, Injectable } from '@angular/core';
import { SmartControlAccessResponse } from '@boels-core/models/smart-control.model';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpService } from './http.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SmartControlAccessService {
  private readonly httpService = inject(HttpService);

  public getSmartControlAccess(): Observable<SmartControlAccessResponse> {
    return this.httpService
      .get$<SmartControlAccessResponse>(`${environment.customBackend}/users/smart-control/access`)
      .pipe(catchError(() => of({ ACCESS_EQUIPMENT_POOLS: false })));
  }
}
