import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, createUrlTreeFromSnapshot, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { filter, map, Observable, of, switchMap, take, tap } from 'rxjs';
import { DebtorState } from '../../state/state/debtors.state';
import { UserStoreService } from '@boels-core/services/user.store.service';
import { SNACKBAR_DEFAULT_OPTIONS } from '@boels-shared/constants/notifications.const';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { baseBoelsUrl } from '../../../environments/environment';
import { LocalizationService } from '@boels-core/services/localization.service';

@Injectable({
  providedIn: 'root',
})
export class DetailsPageGuard {
  protected store = inject(Store);
  protected router = inject(Router);
  protected userStoreService = inject(UserStoreService);
  private readonly matSnackBar = inject(MatSnackBar);
  private readonly translateService = inject(TranslateService);
  private readonly localizationService = inject(LocalizationService);

  /**
   * This guard is used only for details pages, if debtorNumber in query param
   * isn't in the state than we don't allow user to view
   * the page and redirect back to its parent page.
   * @param activatedRouteSnapshot
   */
  public canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot): Observable<boolean> {
    const targetDebtorNumber = activatedRouteSnapshot.queryParams['debtorNumber'];
    const queryParamDebtorIncludedInStore: boolean = this.store
      .selectSnapshot(DebtorState.getActiveDebtorNumbers)
      ?.includes(targetDebtorNumber);

    // If debtor query param isn't in the list of debtor in the state,
    // we redirect user back to the parent view.
    if (!queryParamDebtorIncludedInStore) {
      return this.userStoreService.accounts$.pipe(
        filter((data) => !!data),
        map((data) => data.map((account) => account.debtorNumber)),
        map((allDebtorNumbers) => allDebtorNumbers.includes(targetDebtorNumber)),
        tap((canActivate) => {
          if (!canActivate) {
            this.showInaccessibleDebtorMessage();
            this.router.navigate([createUrlTreeFromSnapshot(activatedRouteSnapshot, ['../']).toString()]);
          }
        }),
        // If canActivate is true, proceed with the changeActiveDebtorNumber call
        switchMap((canActivate) =>
          canActivate
            ? this.userStoreService.changeActiveDebtorNumber([targetDebtorNumber]).pipe(map(() => canActivate))
            : of(false)
        ),
        take(1)
      );
    }

    return of(this.store.selectSnapshot(DebtorState.getActiveDebtorNumbers)?.includes(targetDebtorNumber));
  }

  private showInaccessibleDebtorMessage(): void {
    const snackBarRef = this.matSnackBar.open(
      this.translateService.instant('debtor.noDebtorAccess'),
      this.translateService.instant('debtor.requestDebtorAccess'),
      {
        ...SNACKBAR_DEFAULT_OPTIONS,
        duration: 7000,
      }
    );

    snackBarRef.onAction().subscribe(() => {
      window.location.href = `${baseBoelsUrl}/${this.localizationService.localization}/customer-service`;
    });
  }
}
