import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogTitle, MatDialogClose, MatDialogContent } from '@angular/material/dialog';
import { LangDialogData, Language } from 'src/app/core/models/locales.model';
import { StorageKey } from 'src/app/shared/enums/storage.enum';
import { TranslateModule } from '@ngx-translate/core';
import { LanguageSelectorComponent } from '../language-selector.component';
import { CdkScrollable } from '@angular/cdk/scrolling';

@Component({
  selector: 'app-dialog-language-selector',
  templateUrl: './dialog-language-selector.component.html',
  styleUrls: ['./dialog-language-selector.component.scss'],
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogClose,
    CdkScrollable,
    MatDialogContent,
    LanguageSelectorComponent,
    TranslateModule,
  ],
})
export class DialogLanguageSelectorComponent {
  @Input()
  public language!: Language;
  @Input()
  public baseSiteCode: string = '';
  public selectedCountry: string = localStorage.getItem(StorageKey.CULTURE)?.split('-')[1] || '';

  @Output()
  public languageEventEmitter: EventEmitter<Language> = new EventEmitter();

  public languageIsoCode!: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: LangDialogData) {}

  public emitLanguage(): void {
    this.languageEventEmitter.emit(this.language);
  }
}
