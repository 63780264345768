import { APP_INITIALIZER, Provider, EnvironmentProviders } from '@angular/core';
import { environment } from '../environments/environment';
import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import {
  MSAL_INSTANCE,
  MsalInterceptorConfiguration,
  MSAL_GUARD_CONFIG,
  MSAL_INTERCEPTOR_CONFIG,
  MsalService,
  MsalGuard,
  MsalBroadcastService,
  MsalGuardConfiguration,
} from '@azure/msal-angular';
import { Observable } from 'rxjs';
import { MsalAuthenticationService } from '@boels-core/services/msal-auth.service';

function handleBfCache(): () => void {
  return () => {
    window.addEventListener('pageshow', (event) => {
      if (event.persisted) {
        const sessionStorage = window?.sessionStorage;
        if (!sessionStorage) return;
        if (sessionStorage.length > 0) {
          const authKeys = ['b2clogin', 'msal', 'login'];
          for (let i = 0; i < sessionStorage.length; i++) {
            const key = sessionStorage.key(i) as string;
            if (authKeys.includes(key)) {
              sessionStorage.removeItem(key);
            }
          }
        }
        window.location.reload();
      }
    });
  };
}

function msalInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: environment.msalConfig.instance.auth,
    cache: environment.msalConfig.instance.cache,
    system: environment.msalConfig.instance.system,
  });
}

function msalInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

function msalGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['openid', environment.msalConfig.instance.auth.clientId],
    },
  };
}

function initializeUserAccount(msalAuthenticationService: MsalAuthenticationService): () => Observable<boolean | void> {
  return () => {
    return msalAuthenticationService.initializeMsal();
  };
}

export const provideMsal = (): Array<Provider | EnvironmentProviders> => [
  {
    provide: APP_INITIALIZER,
    useFactory: initializeUserAccount,
    deps: [MsalAuthenticationService],
    multi: true,
  },
  {
    provide: APP_INITIALIZER,
    useFactory: handleBfCache,
    multi: true,
  },
  {
    provide: MSAL_INSTANCE,
    useFactory: msalInstanceFactory,
  },
  {
    provide: MSAL_GUARD_CONFIG,
    useFactory: msalGuardConfigFactory,
  },
  {
    provide: MSAL_INTERCEPTOR_CONFIG,
    useFactory: msalInterceptorConfigFactory,
  },
  MsalService,
  MsalGuard,
  MsalBroadcastService,
];
