<div class="boels-new-feature-modal">
  <header mat-dialog-title>
    <div class="boels-new-feature-modal__close-modal" (click)="closeModal()">
      <img src="assets/images/closebox.svg" alt="Close modal" loading="lazy" />
    </div>
    @if (data?.imagePath; as imageUrl) {
      <div class="boels-new-feature-modal__image">
        <img [src]="imageUrl" />
      </div>
    }
  </header>

  <div mat-dialog-content>
    <div class="boels-new-feature-modal__content">
      <bds-status-pill intent="inform">{{ data.translationKey + 'newFeatureLabel' | translate }}</bds-status-pill>
      @if (data?.showHeading) {
        <h1>{{ data.translationKey + 'heading' | translate }}</h1>
      }
      @if (data?.showContent) {
        <p class="text-lg" [innerHTML]="data.translationKey + 'content' | translate | trustHtml"></p>
      }
    </div>
  </div>

  <div mat-dialog-actions>
    <button class="btn btn-primary btn-small" aria-label="Close modal" (click)="closeModal()">
      {{ 'button.close' | translate }}
    </button>
  </div>
</div>
