import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BoelsNewFeatureModalComponent } from '@boels-shared/components/new-feature-modal/boels-new-feature-modal.component';
import { NewFeatureModalModel } from '@boels-shared/components/new-feature-modal/models/new-feature-modal.model';
import { StorageKey } from '@boels-shared/enums/storage.enum';
import { isAppRunningInProductionEnv, returnDefaultModalPanelClasses } from '@boels-shared/utils/helper.utils';

@Injectable({
  providedIn: 'root',
})
export class NewFeatureModalService {
  constructor(private readonly dialog: MatDialog) {}

  /**
   * Opens the new feature modal if previously wasn't opened,
   * or not PROD env, or language is selected.
   * @param data
   * @param storageKey
   */
  public openModal(data: NewFeatureModalModel, storageKey: string): void {
    //If model already opened once, also if app in PROD mode we don't want to show this, or language modal not selected.
    // TODO: check for PROD mode should be removed once we want to show new feature modals.
    if (
      localStorage.getItem(storageKey) ||
      isAppRunningInProductionEnv() ||
      !localStorage.getItem(StorageKey.CULTURE)
    ) {
      return;
    }

    this.dialog.open(BoelsNewFeatureModalComponent, {
      autoFocus: false,
      disableClose: true,
      panelClass: returnDefaultModalPanelClasses(),
      restoreFocus: false,
      data,
    });
  }
}
