import { registerLocaleData, NgOptimizedImage, AsyncPipe } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router, RouterLink, RouterOutlet } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { DialogLanguageSelectorComponent } from '@boels-core/components/language-selector/dialog-language-selector/dialog-language-selector.component';
import { LOCALES, LanguageData } from '@boels-core/models/locales.model';
import { UserGeoData } from '@boels-core/models/user-geo-data.model';
import { BreakpointService } from '@boels-core/services/breakpoint.service';
import { ChatSFWidgetService } from '@boels-core/services/chat-sf-widget.service';
import { LocalizationService } from '@boels-core/services/localization.service';
import { WindowService } from '@boels-core/services/window.service';
import { NewFeatureModalService } from '@boels-shared/components/new-feature-modal/services/new-feature-modal.service';
import { DestroyReferenceDirective } from '@boels-shared/directives/destroy-reference.directive';
import { StorageKey } from '@boels-shared/enums/storage.enum';
import { ContextMeasurementService } from '@boels-shared/services/context-measurement.service';
import { isIosDevice } from '@boels-shared/utils/device-detection.utils';
import { UserState } from '@boels-state/state/user.state';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { Observable, filter, first, of } from 'rxjs';
import packageJson from '../../../../package.json';
import { baseBoelsUrl } from '../../../environments/environment';
import { AppRoutesUrisConst } from '@boels-shared/constants/app-routes-uris.const';
import { EXTERNAL_URL } from '@boels-core/config/external-url.config';
import { CmsContentPipe, CmsDirectHireComponent } from '@boels-experience/cms';
import { QaHooksDirective } from '@boels-experience/core';
import { NavigationComponent } from '../../core/components/navigation/navigation.component';
import { UserMenuComponent } from '../../core/components/header/user-menu/user-menu.component';
import { DebtorSelectorComponent } from '../../core/components/header/debtor-selector/debtor-selector.component';
import { SiteHeaderComponent } from '@boels-experience/layout';
import { MobileNavigationComponent } from '../../core/components/mobile-navigation/mobile-navigation.component';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  standalone: true,
  imports: [
    MobileNavigationComponent,
    SiteHeaderComponent,
    CmsDirectHireComponent,
    RouterLink,
    NgOptimizedImage,
    DebtorSelectorComponent,
    UserMenuComponent,
    NavigationComponent,
    QaHooksDirective,
    RouterOutlet,
    AsyncPipe,
    TranslateModule,
    CmsContentPipe,
  ],
})
export class MainComponent extends DestroyReferenceDirective implements OnInit, AfterViewInit {
  // This input is linked with URL query parameter.
  @Input() public locale: string;

  public readonly appVersion: string = packageJson?.version;
  public pageClass: string = '';
  public showHeader: boolean = true;
  public isMobile$: Observable<boolean>;
  public displayInformationIcon$: Observable<boolean>;

  private readonly token: string = this.store.selectSnapshot(UserState.selectUserToken);

  private isMobileKeyboardShown: boolean;
  private readonly ipServiceUrl = 'https://api.db-ip.com/v2/free/self';

  protected readonly baseBoelsUrl = baseBoelsUrl;
  public readonly appRoutesUrisConst = AppRoutesUrisConst;
  public readonly EXTERNAL_URL = EXTERNAL_URL;

  constructor(
    public readonly localizationService: LocalizationService,
    private readonly breakpointService: BreakpointService,
    private readonly router: Router,
    private readonly chatSFWidgetService: ChatSFWidgetService,
    private readonly windowService: WindowService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly newFeatureModalService: NewFeatureModalService,
    private readonly contextMeasurementService: ContextMeasurementService,
    private readonly translateService: TranslateService,
    private readonly msalService: MsalService,
    private readonly dialog: MatDialog,
    private readonly store: Store
  ) {
    super();

    this.isMobile$ = this.breakpointService.isMobile$;

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        // based on config properties we return the needed boolean for showing/hiding tooltip in header.
        this.displayInformationIcon$ = of(!!this.activatedRoute.snapshot?.firstChild?.data?.['isHeaderTooltipAllowed']);
      });
  }

  public ngOnInit(): void {
    if (this.msalService.instance.getActiveAccount()) {
      this.initLanguage();
    }

    if (this.locale) {
      this.localizationService
        .getLangConfig()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((langConfigs) => {
          const country: string = this.locale.split('-')[1];
          const suggestedCountry = this.localizationService.getSuggestedCountry(langConfigs, country);

          this.setLanguage({
            langConfig: { country: suggestedCountry },
            language: suggestedCountry?.languages?.find((lang) => lang.isocode === this.locale),
          });

          this.chatSFWidgetService.init();

          this.router.navigate([], {
            queryParams: { locale: null },
            relativeTo: this.activatedRoute,
            queryParamsHandling: 'merge',
          });

          // Wait for the parameter to be removed, then reload
          this.activatedRoute.queryParams.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((params) => {
            if (typeof params['locale'] === 'undefined') {
              location.reload();
            }
          });
        });
    } else {
      this.chatSFWidgetService.init();
    }

    this.handleIOSDeviceScrollIssueWithKeyboard();
    this.launchFeatureOverviewModals();
  }

  public ngAfterViewInit(): void {
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Handles iOS issue with keyboard when open user can
   * bounce scroll down hence the fixed elements goes up.
   * This functions unblurs from input if touch event occurs hence keyboard is closed.
   * @private
   */
  private handleIOSDeviceScrollIssueWithKeyboard(): void {
    if (isIosDevice()) {
      // We detect if visualViewport is available, mostly for phones.
      if ('visualViewport' in window) {
        const VIEWPORT_VS_CLIENT_HEIGHT_RATIO = 0.75;
        // Once keyboard is triggered a resize event is being triggered for visualViewport,
        // hence we can detect if the keyboard is opened or closed with a calc magic.
        this.windowService.window?.visualViewport?.addEventListener('resize', (event: any) => {
          this.isMobileKeyboardShown =
            (event.target.height * event.target.scale) / window.screen.height < VIEWPORT_VS_CLIENT_HEIGHT_RATIO;
        });
      }

      // Attach an touchmove event so we can listen if the user is interacting.
      this.windowService.documentNode?.addEventListener('touchmove', () => {
        // If keyboard is opened, it means user focused on an input, we unfocus and that closes the keyboard.
        if (this.isMobileKeyboardShown) {
          (this.windowService.documentNode as any)?.activeElement?.blur();
        }
      });
    }
  }

  /**
   * Launches off hire feature modal overview.
   * @private
   */
  private launchFeatureOverviewModals(): void {
    this.newFeatureModalService.openModal(
      {
        translationKey: 'featureModals.offHireFeature.',
        storageKey: StorageKey.MODAL_OFF_HIRE,
        imagePath: 'assets/images/off-hire-feature-overview.svg',
        showHeading: true,
        showContent: true,
      },
      StorageKey.MODAL_OFF_HIRE
    );
  }

  private setLanguage(languageData: LanguageData): void {
    if (!languageData?.language) {
      this.locale = null;
      this.initLanguage();
      return;
    }

    const languageIsoCode = languageData.language.isocode;

    registerLocaleData(LOCALES[languageIsoCode], languageIsoCode);
    this.windowService.setWindowLocale(languageIsoCode);
    this.translateService.setDefaultLang(languageIsoCode);
    this.translateService.use(languageIsoCode);

    this.localizationService.setLanguage(languageData);

    this.setChangeLocaleEvents();
  }

  private setChangeLocaleEvents(): void {
    const pageName: string =
      this.router.url.indexOf('?') > 0
        ? this.router.url.substring(this.router.url.indexOf('/') + 1, this.router.url.lastIndexOf('?'))
        : this.router.url.slice(1) || 'dashboard';

    this.contextMeasurementService.setChangeLocaleEvents(pageName);
  }

  private initLanguage(): void {
    if (this.locale) return;
    this.localizationService.countryValue$
      .pipe(
        first(),
        filter((country) => !country),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(async () => {
        try {
          const response = await fetch(this.ipServiceUrl);
          const userGeoData = (await response.json()) as Partial<UserGeoData>;
          this.localizationService.country$.next(userGeoData?.countryCode?.toLowerCase());
          this.openDialog(userGeoData.countryName);
        } catch (e) {
          this.openDialog('');
        }
      });
  }

  private openDialog(countryName: string): void {
    const dialogRef = this.dialog.open(DialogLanguageSelectorComponent, {
      autoFocus: false,
      disableClose: true,
      panelClass: 'modal-language-selector',
      data: { normalizedCountryName: countryName },
      restoreFocus: false,
    });

    dialogRef.afterClosed().pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
  }
}
