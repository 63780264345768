import { APP_INITIALIZER, Injector, importProvidersFrom, ApplicationConfig } from '@angular/core';
import { environment } from '../environments/environment';
import { CommonModule, CurrencyPipe, DatePipe, NgOptimizedImage } from '@angular/common';
import { NgProgressModule } from 'ngx-progressbar';
import { provideHttpClient, withInterceptorsFromDi, withJsonpSupport } from '@angular/common/http';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { provideAnimations } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { provideLottieOptions } from 'ngx-lottie';
import { PageEventsService } from './core/services/page-events.service';
import { AppConfigService } from './core/config/config.service';
import { NewrelicModule } from '@boels-core/modules/newrelic-monitoring/newrelic.module';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import { httpInterceptorProviders } from '@boels-core/interceptors/http.interceptors';
import { provideAppRouter } from './app.routes';
import { provideMsal } from './app.msal';
import { provideTranslation } from './app.translate';
import { provideStore } from './app.ngxs';
import { provideMaterial } from './app.material';
import { provideSentry } from './app.sentry';

const appInitializerFn = (appConfig: AppConfigService) => {
  return async () => {
    return appConfig.loadAppConfig();
  };
};

const initPageEventsService = (injector: Injector) => {
  return () => {
    const service = injector.get(PageEventsService);
    service.init();
  };
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideAppRouter(),
    CurrencyPipe,
    DatePipe,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
    provideHttpClient(withInterceptorsFromDi(), withJsonpSupport()),
    httpInterceptorProviders,
    importProvidersFrom(
      BrowserModule,
      CommonModule,
      NewrelicModule,
      GoogleTagManagerModule.forRoot({
        id: environment.gtm.gtmKey,
      }),
      NgProgressModule,
      NgOptimizedImage
    ),
    provideStore(),
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [AppConfigService],
    },
    PageEventsService,
    {
      provide: APP_INITIALIZER,
      useFactory: initPageEventsService,
      multi: true,
      deps: [Injector],
    },
    provideMsal(),
    provideTranslation(),
    provideLottieOptions({ player: async () => import('lottie-web') }),
    provideAnimations(),
    provideMaterial(),
    provideSentry(),
  ],
};
