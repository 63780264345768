import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { NewrelicMonitoringService } from './newrelic-monitoring.service';

export function initNewrelicMonitoringService(injector: Injector): () => void {
  return () => {
    const newrelicMonitoringService = injector.get(NewrelicMonitoringService);
    newrelicMonitoringService.init();
  };
}

@NgModule({
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initNewrelicMonitoringService,
      deps: [Injector],
      multi: true,
    },
  ],
})
export class NewrelicModule {}
