import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

export const DEFAULT_TRANSLATES = {
  menu: {
    overview: 'menu.overview',
  },
  sfChat: {
    offline: 'Offline',
  },
};

export class CustomMissingTranslationHandler implements MissingTranslationHandler {
  private readonly defaultKey = 'default';
  private readonly DEFAULT_TRANSLATE = DEFAULT_TRANSLATES;

  public handle(params: MissingTranslationHandlerParams): string {
    if (params.interpolateParams) {
      return (params.interpolateParams as any)[this.defaultKey] || params.key;
    }

    if (this.DEFAULT_TRANSLATE.hasOwnProperty(params.key)) {
      return (this.DEFAULT_TRANSLATE as any)[params.key];
    }

    return this.getValueByKey(params.key);
  }

  public getValueByKey(key: string): string {
    return key?.split('.')?.reduce((acc, splittedKey) => acc?.splittedKey, this.DEFAULT_TRANSLATE as any) || key;
  }
}
