import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslateLoader } from '@ngx-translate/core';
import { DEFAULT_LANGUAGE } from '../models/locales.model';

export class CustomTranslateHttpLoader implements TranslateLoader {
  public prefix: string;
  public suffix: string;
  constructor(
    private readonly httpClient: HttpClient,
    prefix: string,
    suffix: string
  ) {
    this.prefix = prefix;
    this.suffix = suffix;
  }

  public getTranslation(lang: string): Observable<File> {
    const defaultPath = `${this.prefix}${lang.split('-')[0]}${this.suffix}`;
    const fallback = `${this.prefix}${DEFAULT_LANGUAGE.split('-')[0]}${this.suffix}`;
    return this.httpClient.get<File>(defaultPath).pipe(
      catchError((): Observable<File> => {
        return this.httpClient.get<File>(fallback);
      })
    );
  }
}
