const CMS_CONTENT_NL_BE = {
  'cms-direct-hire': {
    intro: 'Direct huren?',
    phoneNumber: '070-23 23 24',
    phoneSuffix: '(€ 0,30 p.m.)',
  },
};

const CMS_CONTENT_FR_BE = {
  'cms-direct-hire': {
    intro: 'Location directe?',
    phoneNumber: '070-23 23 24',
    phoneSuffix: '(€ 0,30 p/min.)',
  },
};

const CMS_CONTENT_EN_BE = {
  'cms-direct-hire': {
    intro: 'Want to hire directly?',
    phoneNumber: '070-23 23 24',
    phoneSuffix: '(€ 0,30 p.m.)',
  },
};

const CMS_CONTENT_CS_CZ = {
  'cms-direct-hire': {
    intro: 'Pronájem bez čekání?',
    phoneNumber: '800 192 192',
    phoneSuffix: '',
  },
};

const CMS_CONTENT_EN_CZ = {
  'cms-direct-hire': {
    intro: 'Want to hire directly?',
    phoneNumber: '800 192 192',
    phoneSuffix: '',
  },
};

const CMS_CONTENT_DE_DE = {
  'cms-direct-hire': {
    intro: 'Sofort mieten?',
    phoneNumber: '01805-123 26 35',
    phoneSuffix: '(€ 0,14 p.M.)',
  },
};

const CMS_CONTENT_EN_DE = {
  'cms-direct-hire': {
    intro: 'Want to hire directly?',
    phoneNumber: '01805-123 26 35',
    phoneSuffix: '(€ 0,14 p.M.)',
  },
};

const CMS_CONTENT_IT_IT = {
  'cms-direct-hire': {
    intro: 'Vuoi noleggiare ora?',
    phoneNumber: '02-40326054',
    phoneSuffix: '',
  },
};

const CMS_CONTENT_EN_IT = {
  'cms-direct-hire': {
    intro: 'Want to hire directly?',
    phoneNumber: '02-40326054',
    phoneSuffix: '',
  },
};

const CMS_CONTENT_FR_LU = {
  'cms-direct-hire': {
    intro: 'Location directe?',
    phoneNumber: '900 43333',
    phoneSuffix: '(0,25 €/min.)',
  },
};

const CMS_CONTENT_EN_LU = {
  'cms-direct-hire': {
    intro: 'Want to hire directly?',
    phoneNumber: '900 43333',
    phoneSuffix: '(€0,25 p.m.)',
  },
};

const CMS_CONTENT_DE_LU = {
  'cms-direct-hire': {
    intro: 'Sofort mieten ?',
    phoneNumber: '900 43333',
    phoneSuffix: '(€ 0,25 p.M.)',
  },
};

const CMS_CONTENT_HU_HU = {
  'cms-direct-hire': {
    intro: 'Közvetlenül bérelne?',
    phoneNumber: '+36 24 407744',
    phoneSuffix: '',
  },
};

const CMS_CONTENT_EN_HU = {
  'cms-direct-hire': {
    intro: 'Want to hire directly?',
    phoneNumber: '+36 24 407744',
    phoneSuffix: '',
  },
};

const CMS_CONTENT_NL_NL = {
  'cms-direct-hire': {
    intro: 'Direct huren?',
    phoneNumber: '0900-0551',
    phoneSuffix: '(lokaal tarief)',
  },
};

const CMS_CONTENT_EN_NL = {
  'cms-direct-hire': {
    intro: 'Want to hire directly?',
    phoneNumber: '0900-0551',
    phoneSuffix: '(local rate)',
  },
};

const CMS_CONTENT_DE_AT = {
  'cms-direct-hire': {
    intro: 'Sofort mieten?',
    phoneNumber: '0820-988 005',
    phoneSuffix: '(max. € 0,25 p.M.)',
  },
};

const CMS_CONTENT_EN_AT = {
  'cms-direct-hire': {
    intro: 'Want to hire directly?',
    phoneNumber: '0820-988 005',
    phoneSuffix: '(max. € 0,25 p.m.)',
  },
};

const CMS_CONTENT_PL_PL = {
  'cms-direct-hire': {
    intro: 'Wynajem bezpośredni?',
    phoneNumber: '+48 602 30 00 30',
    phoneSuffix: '',
  },
};

const CMS_CONTENT_EN_PL = {
  'cms-direct-hire': {
    intro: 'Want to hire directly?',
    phoneNumber: '+48 602 30 00 30',
    phoneSuffix: '',
  },
};

const CMS_CONTENT_DE_CH = {
  'cms-direct-hire': {
    intro: 'Sofort mieten?',
    phoneNumber: '0448402929',
    phoneSuffix: '',
  },
};

const CMS_CONTENT_FR_CH = {
  'cms-direct-hire': {
    intro: 'Location directe?',
    phoneNumber: '0448402929',
    phoneSuffix: '',
  },
};

const CMS_CONTENT_IT_CH = {
  'cms-direct-hire': {
    intro: 'Vuoi noleggiare ora?',
    phoneNumber: '0448402929',
    phoneSuffix: '',
  },
};

const CMS_CONTENT_EN_CH = {
  'cms-direct-hire': {
    intro: 'Want to hire directly?',
    phoneNumber: '0448402929',
    phoneSuffix: '',
  },
};

const CMS_CONTENT_SK_SK = {
  'cms-direct-hire': {
    intro: 'Chcete si prenajať priamo?',
    phoneNumber: '02 40 20 80 60',
    phoneSuffix: '',
  },
};

const CMS_CONTENT_EN_SK = {
  'cms-direct-hire': {
    intro: 'Want to hire directly?',
    phoneNumber: '02 40 20 80 60',
    phoneSuffix: '',
  },
};

const CMS_CONTENT_EN_GB = {
  'cms-direct-hire': {
    intro: 'Want to hire directly?',
    phoneNumber: '0370 600 0998',
    phoneSuffix: '',
  },
};

// @ts-expect-error - Recursion is intended and eventually each path must end in a string type
type RecursiveRecord = Record<string, string | RecursiveRecord>;

const CMS_CONTENT_ENTRIES: Record<string, RecursiveRecord> = {
  'nl-be': CMS_CONTENT_NL_BE,
  'fr-be': CMS_CONTENT_FR_BE,
  'en-be': CMS_CONTENT_EN_BE,
  'cs-cz': CMS_CONTENT_CS_CZ,
  'en-cz': CMS_CONTENT_EN_CZ,
  'de-de': CMS_CONTENT_DE_DE,
  'en-de': CMS_CONTENT_EN_DE,
  'it-it': CMS_CONTENT_IT_IT,
  'en-it': CMS_CONTENT_EN_IT,
  'fr-lu': CMS_CONTENT_FR_LU,
  'en-lu': CMS_CONTENT_EN_LU,
  'de-lu': CMS_CONTENT_DE_LU,
  'hu-hu': CMS_CONTENT_HU_HU,
  'en-hu': CMS_CONTENT_EN_HU,
  'nl-nl': CMS_CONTENT_NL_NL,
  'en-nl': CMS_CONTENT_EN_NL,
  'de-at': CMS_CONTENT_DE_AT,
  'en-at': CMS_CONTENT_EN_AT,
  'pl-pl': CMS_CONTENT_PL_PL,
  'en-pl': CMS_CONTENT_EN_PL,
  'de-ch': CMS_CONTENT_DE_CH,
  'fr-ch': CMS_CONTENT_FR_CH,
  'it-ch': CMS_CONTENT_IT_CH,
  'en-ch': CMS_CONTENT_EN_CH,
  'sk-sk': CMS_CONTENT_SK_SK,
  'en-sk': CMS_CONTENT_EN_SK,
  'en-gb': CMS_CONTENT_EN_GB,
};

export const CMS_CONTENT = new Map(Object.entries(CMS_CONTENT_ENTRIES));
