import { Component, inject } from '@angular/core';
import { CookieBotService } from '@boels-core/services/cookie-bot.service';
import { MsalAuthenticationService } from '@boels-core/services/msal-auth.service';
import { GLOBAL_ID_LOADER } from '@boels-shared/constants/global.const';
import { UserState } from '@boels-state/state/user.state';
import { Store } from '@ngxs/store';
import { RouterOutlet } from '@angular/router';
import { NgProgressComponent } from 'ngx-progressbar';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [NgProgressComponent, RouterOutlet],
})
export class AppComponent {
  private readonly cookieBotService = inject(CookieBotService);
  private readonly msalAuthenticationService = inject(MsalAuthenticationService);
  private readonly store = inject(Store);

  protected readonly GLOBAL_ID_LOADER = GLOBAL_ID_LOADER;

  private readonly token: string = this.store.selectSnapshot(UserState.selectUserToken);

  constructor() {
    this.initToken();
    this.cookieBotService.initConsentEvents();
    this.cookieBotService.initCookieBotListeners();
  }

  private initToken(): void {
    if (!this.token) {
      this.msalAuthenticationService.requestLogin$.next(true);
    }
  }
}
