import { APP_INITIALIZER, Injectable, inject, Provider, EnvironmentProviders } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class MaterialInitializerService {
  private readonly matIconRegistry = inject(MatIconRegistry);
  private readonly domSanitizer = inject(DomSanitizer);

  public initializeIcons(): void {
    const availableIcons = [
      'arrow-narrow-left',
      'arrow-narrow-right',
      'checkmark-solid',
      'close',
      'closebox',
      'info',
      'external-link',
      'hourglass-half-solid',
      'error',
      'organization',
      'pen',
      'new-order',
      { iconName: 'three-dots', url: 'icon-three-dots' },
      { iconName: 'clock', url: 'icon-clock' },
      { iconName: 'equipment-pool', url: 'equipment-pool-key' },
      { iconName: 'warning', url: 'warning-icon' },
      { iconName: 'success', url: 'check' },
    ];

    availableIcons.forEach((icon: string | { iconName: string; url: string }) => {
      if (typeof icon === 'string') {
        this.matIconRegistry.addSvgIcon(
          icon,
          this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/images/${icon}.svg`)
        );
      } else {
        this.matIconRegistry.addSvgIcon(
          icon.iconName,
          this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/images/${icon.url}.svg`)
        );
      }
    });
  }
}

export const provideMaterial = (): Array<Provider | EnvironmentProviders> => [
  {
    provide: APP_INITIALIZER,
    useFactory: (materialService: MaterialInitializerService) => () => materialService.initializeIcons(),
    deps: [MaterialInitializerService],
    multi: true,
  },
];
