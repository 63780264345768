import { computed, effect, Injectable, Signal, signal } from '@angular/core';
import { CMS_CONTENT } from '../assets/cms-content';

@Injectable({
  providedIn: 'root',
})
export class CmsContentService {
  private readonly _$locale = signal('');
  public readonly $locale = computed(() => this._$locale());

  public setLocale(locale: string): void {
    if (!CMS_CONTENT.has(locale)) {
      return;
    }

    this._$locale.set(locale);
  }

  public readonly $content: Signal<Map<string, string>> = computed(() => {
    const newContents = this.flatten(CMS_CONTENT.get(this._$locale()));
    return new Map(Object.entries(newContents));
  });

  /**
   * Flattens a nested object into a single-level object with dot-separated keys.
   *
   * This method recursively traverses the nested object and constructs a new
   * flattened object where each key represents the path to a value in the
   * original object, separated by dots. The original `result` parameter remains
   * unchanged throughout the process.
   *
   * @param {object} content - The nested object to flatten.
   * @param {string} [parentKey=''] - The base key to use for the current level of recursion.
   *                                  This is used internally during recursion and should not
   *                                  be set during the initial call.
   * @param {object} [result={}] - The object that accumulates the flattened key-value pairs.
   *                               This parameter is optional and primarily used for internal
   *                               recursive calls.
   * @returns {object} - A new object containing the flattened key-value pairs.
   *
   * @example
   * const nestedObj = {
   *   a: {
   *     aa: '1',
   *     ab: {
   *       aba: '2',
   *     },
   *   },
   *   b: '3',
   * };
   *
   * const flattenedObj = flatten(nestedObj);
   * console.log(flattenedObj);
   * // Output: { 'a.aa': '1', 'a.ab.aba': '2', 'b': '3' }
   */
  private flatten(
    content: any,
    parentKey: string = '',
    result: { [key: string]: string } = {}
  ): { [key: string]: string } {
    const output = { ...result };
    for (const key in content) {
      if (!content.hasOwnProperty(key)) {
        continue;
      }

      const newKey = parentKey ? `${parentKey}.${key}` : key;
      if (typeof content[key] === 'object' && content[key] !== null) {
        const nestedOutput = this.flatten(content[key], newKey, output);
        Object.assign(output, nestedOutput);
      } else {
        output[newKey] = content[key] as string;
      }
    }

    return output;
  }
}
