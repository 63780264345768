@if (language) {
  <a
    (click)="emitLanguage()"
    mat-dialog-close
    [attr.data-dm]="
      {
        data_type: 'cta',
        item_name: 'language-selector language-item',
        country: countryCode,
        language: languageIsoCode
      } | json
    "
    [class.is-active]="language.selected"
  >
    {{ language.nativeLanguageName }}
    <img
      [src]="(isMobile$ | async) ? 'assets/images/arrow-right.svg' : 'assets/images/chevron.svg'"
      [alt]="language.nativeLanguageName"
      loading="lazy"
    />
  </a>
}
