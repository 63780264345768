@if (langConfig) {
  <div class="language-selector__flag">
    <img [src]="imageUrl" [alt]="langConfig.country.isocode | lowercase" loading="lazy" />
  </div>
  <div class="language-selector__country" [class.is-active]="langConfig.country.selected">
    {{ langConfig.country.nativeName }}
  </div>
  <div class="language-selector__language">
    @for (language of langConfig.country.languages; track trackByIsoCode($index, language)) {
      <div class="language-selector__language-item">
        <app-language-selector-item-link
          [language]="language"
          [countryCode]="langConfig.country.isocode"
          (languageEventEmitter)="emitLanguage($event)"
        >
        </app-language-selector-item-link>
      </div>
    }
  </div>
}
