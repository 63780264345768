import { inject, Injectable } from '@angular/core';
import { combineLatest, filter, take } from 'rxjs';
import { SharedState } from '@boels-state/state/shared.state';
import { CookieBotConsentTypeEnum } from '@boels-core/enums/cookie-bot.enum';
import { CookieBotService } from '@boels-core/services/cookie-bot.service';
import { Store } from '@ngxs/store';
import { isAppRunningInProductionEnv } from '@boels-shared/utils/helper.utils';

@Injectable({
  providedIn: 'root',
})
export class NewrelicMonitoringService {
  private readonly cookieBotService = inject(CookieBotService);
  private readonly store = inject(Store);

  public init(): void {
    if (!isAppRunningInProductionEnv()) {
      return;
    }

    combineLatest([
      this.store.select(SharedState.isCookieBotInitialized).pipe(filter((result) => result)),
      this.cookieBotService
        .getCookieBotConsentStateByProperty(CookieBotConsentTypeEnum.STATISTICS)
        .pipe(filter((result) => !!result)),
    ])
      // We only should to inject this script once (as soon as the user accepted cookies)
      .pipe(take(1))
      .subscribe(this.injectMonitoringScript);
  }

  private injectMonitoringScript(): void {
    const scriptEl = window.document.createElement('script');
    scriptEl.defer = true;
    scriptEl.src = 'assets/scripts/monitoring-script.js';
    window.document.body.appendChild(scriptEl);
  }
}
