import { Directive, HostListener, inject, Input, Signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { Debtor } from 'src/app/core/models/user.model';
import { BreakpointService } from 'src/app/core/services/breakpoint.service';
import { UserStoreService } from 'src/app/core/services/user.store.service';
import { DestroyReferenceDirective } from 'src/app/shared/directives/destroy-reference.directive';
import { Observable } from 'rxjs';
import { BoelsSelectedDebtorDialogComponent } from '@boels-shared/components/selected-debtor-dialog/selected-debtor-dialog.component';

@Directive({
  selector: '[boelsDebtorSelector]',
  standalone: true,
})
export class DebtorSelectorDirective extends DestroyReferenceDirective {
  @Input() public activeAccounts: Array<Debtor> = [];

  private readonly userStoreService = inject(UserStoreService);
  private readonly dialog = inject(MatDialog);
  private readonly breakpointService = inject(BreakpointService);

  public accounts$: Observable<ReadonlyArray<Debtor>> = this.userStoreService.accounts$;
  public $activeAccounts: Signal<Array<Debtor>> = toSignal(this.userStoreService.activeAccounts$);

  private isDialogActive: boolean = false;
  private isMobile: boolean = false;

  constructor() {
    super();

    this.breakpointService.isMobile$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isMobile) => (this.isMobile = isMobile));
  }

  @HostListener('click')
  public openDialog(): void {
    if (this.isDialogActive) return;

    this.isDialogActive = true;
    const dialogRef = this.dialog.open(BoelsSelectedDebtorDialogComponent, {
      autoFocus: false,
      disableClose: true,
      panelClass: 'modal-debtor-selector',
      hasBackdrop: !this.isMobile,
      enterAnimationDuration: this.isMobile ? '0ms' : '300ms',
      exitAnimationDuration: this.isMobile ? '0ms' : '300ms',
      restoreFocus: false,
      data: {
        accounts$: this.userStoreService.accounts$,
        activeAccounts: this.$activeAccounts(),
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((modalResponseData: Array<Debtor> | boolean) => {
        this.isDialogActive = false;

        // Modal afterClosed response can be an array too.
        if (Array.isArray(modalResponseData) && modalResponseData?.length) {
          this.changeAccount(modalResponseData);
        }
      });
  }

  private changeAccount(accounts: Array<Debtor>): void {
    this.userStoreService.changeActiveDebtorNumber(accounts.map((account) => account.debtorNumber));
  }
}
