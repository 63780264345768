<div class="selected-debtor-dialog__container">
  <div mat-dialog-title>
    @if (isMobile$ | async) {
      <button [mat-dialog-close]="false">
        <img src="../../../../assets/images/chevron.svg" [alt]="'debtor.debtors' | translate" loading="lazy" />
      </button>
    }
    <span class="title">{{ 'debtor.debtors' | translate }}</span>
    <button class="btn-close-modal" type="button" (click)="closeModal()" aria-label="Close modal">
      <img src="../../../../assets/images/closebox.svg" alt="Close modal" loading="lazy" />
    </button>
  </div>

  <mat-dialog-content [ngClass]="{ 'no-error': isCheckedAtLeastOneAccount }">
    @if (accounts.length > 0) {
      @if (!isCheckedAtLeastOneAccount) {
        <div class="error-message">
          <img src="../../../../assets/images/info.svg" [alt]="'debtor.errorMessage' | translate" loading="lazy" />
          {{ 'debtor.errorMessage' | translate }}
        </div>
      }
      <div class="select-all">
        <bds-input-checkbox
          [formControl]="toggleAllRows"
          [indeterminate]="toggleAllRows.indeterminate"
          (change)="rowsSelectedToggle()"
        >
          <b>{{ 'debtor.selectAll' | translate }}</b>
        </bds-input-checkbox>
      </div>
      <div class="control--checkbox">
        @for (item of accounts; track item) {
          <bds-input-checkbox
            class="button-reset item-button"
            [checked]="item?.isChecked"
            (change)="onSelect(item)"
            [qa]="'debtor-listItem'"
          >
            <div class="organization-container">
              <div class="info-container">
                <span class="info-title">{{ item.name }}</span>
                <span class="info-subtitle">
                  @if (item.nameExtraInfo; as extraInfo) {
                    <span>{{ extraInfo }}</span>
                  }
                  @if (item.debtorNumber; as debtorNumber) {
                    <span>{{ debtorNumber }}</span>
                  }
                </span>
              </div>
            </div>
          </bds-input-checkbox>
        }
      </div>
    }
  </mat-dialog-content>

  <div mat-dialog-actions>
    <button
      class="btn btn-small"
      [ngClass]="{ 'btn-secondary': (isMobile$ | async), 'btn-primary': !(isMobile$ | async) }"
      [mat-dialog-close]="activeAccounts"
      [disabled]="!isCheckedAtLeastOneAccount || !isChanged"
      aria-label="Close modal"
    >
      {{ 'debtor.save' | translate }}
    </button>
    <button class="btn btn-outlined btn-small" (click)="closeModal()" aria-label="Close modal">
      {{ 'button.cancel' | translate }}
    </button>
  </div>
</div>
