<div class="language-selector-container">
  <div mat-dialog-title>
    @if (selectedCountry) {
      <button [mat-dialog-close]="false">
        <img src="assets/images/chevron.svg" alt="Language menu" loading="lazy" />
      </button>
    }
    <span class="title">{{ 'languageSelector.CountryAndLanguage' | translate }}</span>
    @if (selectedCountry) {
      <button class="btn-close-modal" type="button" [mat-dialog-close]="true" aria-label="Close modal">
        <img src="assets/images/closebox.svg" alt="Close modal" loading="lazy" />
      </button>
    }
  </div>
  <mat-dialog-content>
    <app-language-selector [localizedCountryName]="data.normalizedCountryName || ''"></app-language-selector>
  </mat-dialog-content>
</div>
