import { inject, Pipe, PipeTransform } from '@angular/core';
import { CmsContentService } from '@boels-experience/cms';

@Pipe({
  name: 'cmsContent',
  standalone: true,
})
export class CmsContentPipe implements PipeTransform {
  private readonly cmsContentService = inject(CmsContentService);

  public transform(value: string): string {
    return this.cmsContentService.$content().get(value) ?? '';
  }
}
