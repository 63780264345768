import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CountryLangConfig, Language, LanguageData } from 'src/app/core/models/locales.model';
import { ImageLoaderService } from 'src/app/core/services/image-loader.service';
import { LanguageSelectorItemLinkComponent } from './language-selector-item-link/language-selector-item-link.component';
import { LowerCasePipe } from '@angular/common';

@Component({
  selector: 'app-language-selector-item',
  templateUrl: './language-selector-item.component.html',
  styleUrls: ['./language-selector-item.component.scss'],
  standalone: true,
  imports: [LanguageSelectorItemLinkComponent, LowerCasePipe],
})
export class LanguageSelectorItemComponent implements OnChanges {
  private readonly flagsFolderUrl = '/assets/images/flags/Flag_';
  @Input()
  public langConfig!: CountryLangConfig;
  @Output()
  public languageEventEmitter: EventEmitter<LanguageData> = new EventEmitter();

  public imageUrl!: string;

  constructor(private readonly imageLoaderService: ImageLoaderService) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['langConfig']) {
      this.imageUrl = this.imageLoaderService.getImagePath(this.flagsFolderUrl, this.langConfig.country.isocode);
    }
  }

  public emitLanguage(language: Language): void {
    const languageData: LanguageData = {
      language,
      langConfig: this.langConfig,
    };
    this.languageEventEmitter.emit(languageData);
  }

  public trackByIsoCode(index: number, language: Language): string {
    return language.isocode;
  }
}
