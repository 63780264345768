import { Component, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DestroyReferenceDirective } from 'src/app/shared/directives/destroy-reference.directive';
import { StorageKey } from 'src/app/shared/enums/storage.enum';
import { ContextMeasurementService } from 'src/app/shared/services/context-measurement.service';
import { Country, CountryLangConfig, LanguageData, LOCALES } from '../../models/locales.model';
import { LocalizationService } from '../../services/localization.service';
import { registerLocaleData, AsyncPipe } from '@angular/common';
import { WindowService } from '@boels-core/services/window.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { LanguageSelectorItemComponent } from './language-selector-item/language-selector-item.component';
import { MatDialogClose } from '@angular/material/dialog';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
  standalone: true,
  imports: [MatDialogClose, LanguageSelectorItemComponent, AsyncPipe, TranslateModule],
})
export class LanguageSelectorComponent extends DestroyReferenceDirective {
  public langConfigs$!: Observable<Array<CountryLangConfig>>;
  public suggestedCountry: string = '';
  @Input()
  public localizedCountryName: string = '';

  constructor(
    private readonly localizationService: LocalizationService,
    private readonly contextMeasurementService: ContextMeasurementService,
    private readonly router: Router,
    private readonly translateService: TranslateService,
    private readonly windowService: WindowService,
    private readonly route: ActivatedRoute
  ) {
    super();
    this.langConfigs$ = this.localizationService.getLangConfig();

    this.localizationService.countryValue$
      .pipe(
        filter((country) => !!country),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(
        (country) =>
          (this.suggestedCountry = !localStorage.getItem(StorageKey.COUNTRY_LANGUAGE)?.split(' - ')[1] ? country : '')
      );
  }

  public setLanguage(languageData: LanguageData): void {
    if (!languageData?.language) return;

    const languageIsoCode = languageData.language.isocode;

    registerLocaleData(LOCALES[languageIsoCode], languageIsoCode);
    this.windowService.setWindowLocale(languageIsoCode);
    this.translateService.setDefaultLang(languageIsoCode);
    this.translateService.use(languageIsoCode);

    this.localizationService.setLanguage(languageData);

    this.setChangeLocaleEvents();

    this.router.navigate(['.'], {
      queryParams: { locale: null },
      relativeTo: this.route,
      queryParamsHandling: 'merge',
    });

    // Wait for the parameter to be removed, then reload
    this.route.queryParams.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((params) => {
      if (typeof params['locale'] === 'undefined') {
        location.reload();
      }
    });
  }

  public trackByIsoCode(index: number, langConfig: CountryLangConfig): string {
    return langConfig.country.isocode;
  }

  public getSuggestedCountry(langConfigs: Array<CountryLangConfig>, suggestedCountry: string): Country {
    return this.localizationService.getSuggestedCountry(langConfigs, suggestedCountry);
  }

  private setChangeLocaleEvents(): void {
    const pageName: string =
      this.router.url.indexOf('?') > 0
        ? this.router.url.substring(this.router.url.indexOf('/') + 1, this.router.url.lastIndexOf('?'))
        : this.router.url.slice(1) || 'dashboard';

    this.contextMeasurementService.setChangeLocaleEvents(pageName);
  }
}
