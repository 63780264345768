import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
  ActionMemoryContainerItemBase,
  ActionMemoryContextAction,
  ActionMemoryFilter,
} from '@boels-core/models/action-memory.model';
import { MsalAuthenticationService } from '@boels-core/services/msal-auth.service';
import { UserService } from '@boels-core/services/user.service';
import { OffHireActionMemoryValueOld } from '@boels-features/products-off-hire/models/products-off-hire-action-memory.model';
import { OffHirePayloadModel } from '@boels-features/products-off-hire/models/products-off-hire-payload.model';
import { GLOBAL_DEMO_ACCOUNT_LOGOUT_TIME_HOURS } from '@boels-shared/constants/global.const';
import { StorageKey } from '@boels-shared/enums/storage.enum';
import { momentReturnHoursDifference } from '@boels-shared/utils/moment.utils';
import { Observable, of } from 'rxjs';
import { DEMO_ACCOUNT_MOCK_URLS } from '../mocks/demo-account.mocks';

@Injectable()
export class MocksInterceptor implements HttpInterceptor {
  private readonly userService = inject(UserService);
  private readonly msalAuthenticationService = inject(MsalAuthenticationService);

  private offHireRequestBody: Array<
    ActionMemoryContainerItemBase<OffHirePayloadModel, Array<OffHireActionMemoryValueOld>>
  > = [];
  private readonly apiMainUrl: string = 'exp/web/v2/api';

  /**
   * Mock is enabled only if it matches with the logged in email
   * with the email from the env file, than it searches
   * through our mock data for the specific endpoint
   * and returns the mock data, otherwise it
   * will still make the actual request.
   * @param request
   * @param next
   */
  public intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<any>> {
    // We will have specific username that will work only with mocks.
    const mockEnabled: boolean = this.msalAuthenticationService.isDemoUser();
    let currentTimestamp: number = this.demoAccountTimestamp();

    // If mock is not enabled, we don't make any changes to the request.
    if (!mockEnabled) {
      return next.handle(request);
    }

    // If mock is enabled and timestamp in local storage is 0, it means
    // it's very first time logging in, we gotta set next timestamp of current time +1 hour.
    if (mockEnabled && !currentTimestamp) {
      this.msalAuthenticationService.checkForDemoUser();
      currentTimestamp = this.demoAccountTimestamp();
    }

    // We only intercept requests from our APIs and if it matches with the logged-in username.
    if (request.url.includes(this.apiMainUrl)) {
      // It forces user to login again after 1 hour of usage.
      if (momentReturnHoursDifference(new Date(currentTimestamp)) >= GLOBAL_DEMO_ACCOUNT_LOGOUT_TIME_HOURS) {
        this.userService.logoutUser();
      }

      const foundMockData = DEMO_ACCOUNT_MOCK_URLS[this.cleanupUrl(request.url)];

      if (foundMockData) {
        // eslint-disable-next-line no-console
        console.log(
          '%c[MOCK INTERCEPTOR]: FOUND MOCK: URL/DATA',
          'color:green',
          this.cleanupUrl(request.url),
          foundMockData
        );
        return of(new HttpResponse({ status: 200, body: foundMockData }));
      }

      // eslint-disable-next-line no-console
      console.log('%c[MOCK INTERCEPTOR]: UNABLE TO FIND MOCK WITH URL:', 'color:red', this.cleanupUrl(request.url));
    }

    const response = this.handleActionMemoryRequests(request);
    if (response) {
      return response;
    }

    return next.handle(request);
  }

  private handleActionMemoryRequests<T = null>(request: HttpRequest<T>): false | Observable<HttpEvent<any>> {
    if (!request.url.includes('/v1/action-memory')) {
      return false;
    }

    if (request.method === 'POST') {
      const requestBody = request.body as ActionMemoryContainerItemBase<
        OffHirePayloadModel,
        Array<OffHireActionMemoryValueOld>
      >;

      if (requestBody.contextAction === ActionMemoryContextAction.OffHire) {
        this.offHireRequestBody = [
          request.body as ActionMemoryContainerItemBase<OffHirePayloadModel, Array<OffHireActionMemoryValueOld>>,
        ];
      }

      return of(new HttpResponse({ status: 201 }));
    }

    const filter: ActionMemoryFilter | null = JSON.parse(
      new URLSearchParams(new URL(request.url).search).get('filter')
    );

    if (filter && filter.items?.find((item) => item.contextAction === ActionMemoryContextAction.OffHire)) {
      return of(
        new HttpResponse({
          status: 200,
          body: this.offHireRequestBody,
        })
      );
    }

    return of(
      new HttpResponse({
        status: 200,
        body: [],
      })
    );
  }

  /**
   * Cleans up the url from query parameters
   * and returns url without https and v2 string.
   * @param url
   * @private
   */
  private cleanupUrl(url: string): string {
    const newUrl = new URL(url);
    newUrl.search = '';
    return newUrl?.toString()?.split(this.apiMainUrl)[1];
  }

  /**
   * Returns demo account timestamp from local storage.
   * @private
   */
  private demoAccountTimestamp(): number {
    return +localStorage?.getItem(StorageKey.DEMO_USER_TIMESTAMP);
  }
}
