import { inject, EnvironmentProviders, Provider, APP_INITIALIZER } from '@angular/core';
import {
  PreloadAllModules,
  provideRouter,
  RouteReuseStrategy,
  Routes,
  withComponentInputBinding,
  withInMemoryScrolling,
  withPreloading,
  withRouterConfig,
} from '@angular/router';
import { AppRoutesUrisConst } from '@boels-shared/constants/app-routes-uris.const';
import { MainComponent } from './containers/main/main.component';
import { DetailsPageGuard } from '@boels-core/guards/details-page.guard';
import { NotFoundComponent } from '@boels-core/components/not-found/not-found.component';
import { ComponentCanDeactivate, ComponentWithDeactivation } from '@boels-shared/guards/can-deactivate.guard';
import { CustomRouteReuseStrategy } from './shared/strategies/router-reuse-strategy';
import { LocationHistoryService } from '@boels-shared/services/location-history.service';
import { MsalAuthenticationService } from '@boels-core/services/msal-auth.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: AppRoutesUrisConst.Dashboard(true),
    pathMatch: 'full',
  },
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: AppRoutesUrisConst.Dashboard(true),
        loadComponent: async () =>
          (await import('./features/dashboard/components/dashboard/dashboard.component')).DashboardComponent,
      },
      {
        path: AppRoutesUrisConst.Account(true),
        loadComponent: async () =>
          (await import('./features/account-details/components/account-settings-page/account-settings-page.component'))
            .AccountSettingsPageComponent,
        canDeactivate: [
          async (component: ComponentWithDeactivation) => inject(ComponentCanDeactivate).canDeactivate(component),
        ],
      },
      {
        path: AppRoutesUrisConst.Contracts(true),
        loadComponent: async () =>
          (await import('./features/contracts/components/contract/contract.component')).ContractComponent,
      },
      {
        path: `${AppRoutesUrisConst.Contracts(true)}/:id`,
        canActivate: [DetailsPageGuard],
        loadComponent: async () =>
          (await import('./features/contract-details/components/contract-details-page/contract-details-page.component'))
            .ContractDetailsPageComponent,
      },
      {
        path: AppRoutesUrisConst.Invoices(true),
        loadComponent: async () =>
          (await import('./features/invoices/components/invoice/invoice.component')).InvoiceComponent,
        data: {
          isHeaderTooltipAllowed: true,
        },
      },
      {
        path: `${AppRoutesUrisConst.Invoices(true)}/:id`,
        canActivate: [DetailsPageGuard],
        loadComponent: async () =>
          (await import('./features/invoice-details/components/invoice-details-page/invoice-details-page.component'))
            .InvoiceDetailsPageComponent,
      },
      {
        path: AppRoutesUrisConst.Products(true),
        loadComponent: async () =>
          (await import('./features/products/components/products/products.component')).ProductsComponent,
      },
      {
        path: `${AppRoutesUrisConst.Products(true)}/:id`,
        canActivate: [DetailsPageGuard],
        loadComponent: async () =>
          (await import('./features/product-details/components/product-details.component')).ProductDetailsComponent,
      },
      { path: AppRoutesUrisConst.NotFound(true), component: NotFoundComponent },
      { path: '**', redirectTo: '/404' },
    ],
  },
];

export const provideAppRouter = (): Array<Provider | EnvironmentProviders> => [
  provideRouter(
    routes,
    withRouterConfig({
      canceledNavigationResolution: 'computed',
    }),
    withComponentInputBinding(),
    withPreloading(PreloadAllModules),
    withInMemoryScrolling({
      scrollPositionRestoration: 'top',
    })
  ),
  { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
  {
    provide: APP_INITIALIZER,
    useFactory: (locationHistoryService: LocationHistoryService) => () => {},
    deps: [LocationHistoryService],
    multi: true,
  },
];
