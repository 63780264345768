<div class="region">
  @if (langConfigs$ | async; as langConfigs) {
    @if (suggestedCountry && getSuggestedCountry(langConfigs, suggestedCountry); as country) {
      <div class="region-suggestion">
        <h1>
          {{
            'languageSelector.suggestionHeader'
              | translate
                : {
                    countryName: localizedCountryName || country.nativeName
                  }
          }}
        </h1>
        <div class="region-suggestion-text">
          <span>{{ 'languageSelector.suggestionText' | translate }}</span>
        </div>
        <div class="region-suggestion-bottom">
          @for (language of country.languages; track language) {
            <button
              (click)="
                setLanguage({
                  langConfig: { country: country },
                  language: language
                })
              "
              mat-dialog-close
              class="btn btn-secondary"
            >
              {{ language.nativeLanguageName }}
            </button>
          }
        </div>
        <h4>
          {{ 'languageSelector.selectionHeader' | translate }}
        </h4>
      </div>
    } @else {
      <h1>{{ 'languageSelector.title' | translate }}</h1>
    }
    <ul class="language-selector-container">
      @for (langConfig of langConfigs; track trackByIsoCode($index, langConfig)) {
        <li class="language-selector">
          <app-language-selector-item [langConfig]="langConfig" (languageEventEmitter)="setLanguage($event)">
          </app-language-selector-item>
        </li>
      }
    </ul>
  }
</div>
