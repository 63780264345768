@if (accounts$ | async; as accounts) {
  @if ($activeAccounts(); as activeAccounts) {
    <div class="selected-debtor__content">
      @if (activeAccounts.length > 1) {
        <span
          [innerHtml]="
            'debtor.debtorsSelected' | translate: { selected: activeAccounts.length, all: accounts.length } | trustHtml
          "
        ></span>
      } @else {
        <span class="info-title" [tooltip]="activeAccounts[0].name">
          {{ activeAccounts[0].name?.length ? activeAccounts[0].name : activeAccounts[0].debtorNumber }}
        </span>
        @if (activeAccounts[0]?.nameExtraInfo; as extraInfo) {
          <span class="info-subtitle">
            <span>{{ extraInfo }}</span>
            @if (activeAccounts[0].name?.length && activeAccounts[0].debtorNumber) {
              <span>
                {{ activeAccounts[0].debtorNumber }}
              </span>
            }
          </span>
        }
      }
    </div>
  }
}
