import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { Language } from 'src/app/core/models/locales.model';
import { BreakpointService } from 'src/app/core/services/breakpoint.service';
import { MatDialogClose } from '@angular/material/dialog';
import { AsyncPipe, JsonPipe } from '@angular/common';

@Component({
  selector: 'app-language-selector-item-link',
  templateUrl: './language-selector-item-link.component.html',
  styleUrls: ['./language-selector-item-link.component.scss'],
  standalone: true,
  imports: [MatDialogClose, AsyncPipe, JsonPipe],
})
export class LanguageSelectorItemLinkComponent implements OnChanges {
  @Input()
  public language!: Language;
  @Input()
  public countryCode: string = '';

  @Output()
  public readonly languageEventEmitter: EventEmitter<Language> = new EventEmitter();

  public languageIsoCode!: string;
  public isMobile$ = this.breakpointService.isMobile$;

  constructor(private readonly breakpointService: BreakpointService) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['language'] || changes['countryCode']) {
      this.languageIsoCode = this.getGenericLanguage();
    }
  }

  public emitLanguage(): void {
    this.languageEventEmitter.emit(this.language);
  }

  private getGenericLanguage(): string {
    return this.language.isocode?.split('_')[0] || '';
  }
}
