import { Inject, Injectable, Optional } from '@angular/core';
import { TranslateCompiler } from '@ngx-translate/core';
import MessageFormat, { MessageFormatOptions, MessageFunction } from '@messageformat/core';
import { defaultConfig, MessageFormatConfig, MESSAGE_FORMAT_CONFIG } from './message-format-config';

export type CompilationResult = MessageFunction<'string'> | string;

function ensureCase(
  icuMessage: string,
  {
    ensuredCase,
    copyFromCase,
  }: {
    ensuredCase: string;
    copyFromCase: string;
  }
): string {
  // Regex to capture the plural block and the individual cases (one, few, many, other, etc.)
  const pluralRegex = /{(\w+\s*,\s*plural\s*,)(.+)}\s*$/;

  // Match the plural block
  const match = icuMessage.match(pluralRegex);

  if (!match) {
    // No plural block found, return original message
    return icuMessage;
  }

  const pluralContent = match[2]; // The part inside the plural block
  const pluralArg = match[1]; // The argument name (e.g., COUNT)

  // Check if the "ensuredCase" case exists
  // See https://regex101.com/r/OCU8kH/1
  const ensuredCaseRegex = new RegExp(
    `(${ensuredCase}(\\s*\\{((?:[^{}']+|'[^']*'|\\{(?:[^{}']+|'[^']*'|\\{[^{}]*})*})*)}))`
  );
  const ensuredCaseExists = pluralContent.match(ensuredCaseRegex);

  if (ensuredCaseExists) {
    // If "other" case exists, do nothing and return the original message
    return icuMessage;
  }

  // Regex to find the case we copy from
  // See https://regex101.com/r/OCU8kH/1
  const copyCaseRegex = new RegExp(
    `(${copyFromCase}(\\s*\\{((?:[^{}']+|'[^']*'|\\{(?:[^{}']+|'[^']*'|\\{[^{}]*})*})*)}))`
  );
  const copyMatch = pluralContent.match(copyCaseRegex);

  if (!copyMatch) {
    // The copy case found, nothing to copy, return original message
    return icuMessage;
  }

  // Reconstruct the full ICU message with the updated plural block
  return icuMessage.replace(pluralRegex, `{${pluralArg}${pluralContent} ${ensuredCase}${copyMatch[2]}}`);
}

/**
 * This compiler expects ICU syntax and compiles the expressions with messageformat.js
 */
@Injectable()
export class TranslateMessageFormatCompiler extends TranslateCompiler {
  private readonly mfCache = new Map<string, MessageFormat>();
  private readonly messageFormatOptions: MessageFormatOptions<'string'>;
  private readonly throwOnError: boolean;
  private readonly fallbackPrefix?: string;

  constructor(
    @Optional()
    @Inject(MESSAGE_FORMAT_CONFIG)
    config?: MessageFormatConfig
  ) {
    super();

    const {
      formatters: customFormatters,
      biDiSupport,
      strictNumberSign: strict,
      currency,
      strictPluralKeys,
      throwOnError,
      fallbackPrefix,
    } = {
      ...defaultConfig,
      ...config,
    };

    this.messageFormatOptions = {
      customFormatters,
      biDiSupport,
      strict,
      currency,
      strictPluralKeys,
    };
    this.throwOnError = !!throwOnError;
    this.fallbackPrefix = fallbackPrefix;
  }

  public compile<Result extends CompilationResult = MessageFunction<'string'>>(value: string, lang: string): Result {
    if (this.fallbackPrefix && value.startsWith(this.fallbackPrefix)) {
      return value.slice(this.fallbackPrefix.length) as Result;
    }

    let result: MessageFunction<'string'>;

    try {
      result = this.getMessageFormatInstance(lang).compile(value);
    } catch (_err) {
      try {
        if (['pl', 'pl-pl'].includes(lang) && String(_err).includes(`No 'other' form found`)) {
          result = this.getMessageFormatInstance(lang).compile(
            ensureCase(value, { ensuredCase: 'other', copyFromCase: 'many' })
          );
        } else {
          throw new Error('Unable to fix Polish message format');
        }
      } catch (__err) {
        const err = _err;

        if (this.throwOnError) {
          throw err;
        }

        window.console.error(err);
        window.console.error(
          `[ngx-translate-messageformat-compiler] Could not compile message for lang '${lang}': '${value}'`
        );
        result = compileFallback(value, lang);
      }
    }

    if (!this.throwOnError) {
      result = wrapInterpolationFunction(result, value);
    }

    return result as Result;
  }

  public compileTranslations(translations: any, lang: string): any {
    if (typeof translations === 'string') {
      return this.compile(translations, lang);
    }

    return Object.keys(translations).reduce<{ [key: string]: any }>((acc, key) => {
      const value = translations[key];
      acc[key] = this.compileTranslations(value, lang);
      return acc;
    }, {});
  }

  private getMessageFormatInstance(locale: string): MessageFormat {
    if (!this.mfCache.has(locale)) {
      this.mfCache.set(locale, new MessageFormat<'string'>(locale, this.messageFormatOptions));
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.mfCache.get(locale)!;
  }
}

function wrapInterpolationFunction(fn: MessageFunction<'string'>, message: string): MessageFunction<'string'> {
  return (params: any) => {
    let result: string = message;

    try {
      result = fn(params);
    } catch (err) {
      window.console.error(err);
      window.console.error(
        `[ngx-translate-messageformat-compiler] Could not interpolate '${message}' with params '${params}'`
      );
    }

    return result;
  };
}

function compileFallback(message: string, lang: string): MessageFunction<'string'> {
  return () => {
    window.console.warn(
      `[ngx-translate-messageformat-compiler] Falling back to original invalid message: '${message}' ('${lang}')`
    );

    return String(message);
  };
}
