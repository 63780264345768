import {
  APP_INITIALIZER,
  importProvidersFrom,
  Injectable,
  inject,
  Provider,
  EnvironmentProviders,
} from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { CustomMissingTranslationHandler } from './core/services/custom-missing-translation-handler';
import { HttpClient } from '@angular/common/http';
import { DEFAULT_LANGUAGE, LOCALES } from './core/models/locales.model';
import { TranslateMessageFormatCompiler } from './shared/utils/translate-message-format-compiler';
import {
  TranslateModule,
  TranslateCompiler,
  TranslateLoader,
  MissingTranslationHandler,
  TranslateService,
} from '@ngx-translate/core';
import { LocalizationService } from '@boels-core/services/localization.service';
import { WindowService } from '@boels-core/services/window.service';
import { CmsContentService } from '@boels-experience/cms';
import { CustomTranslateHttpLoader } from '@boels-core/services/custom-translate-http-loader';

function HttpLoaderFactory(http: HttpClient): CustomTranslateHttpLoader {
  return new CustomTranslateHttpLoader(http, './assets/i18n/', '.json');
}

@Injectable({ providedIn: 'root' })
export class LanguageInitializerService {
  private readonly localizationService = inject(LocalizationService);
  private readonly translateService = inject(TranslateService);
  private readonly windowService = inject(WindowService);
  private readonly cmsContentService = inject(CmsContentService);

  public initializeLanguage(): void {
    const lang = this.localizationService.localization;

    registerLocaleData(LOCALES[lang], lang);
    this.windowService.setWindowLocale(lang);
    this.translateService.setDefaultLang(lang);
    this.translateService.use(lang);

    this.cmsContentService.setLocale(lang);
    this.localizationService.culture$.subscribe((culture) => {
      this.cmsContentService.setLocale(culture);
    });
  }
}

export const provideTranslation = (): Array<Provider | EnvironmentProviders> => [
  importProvidersFrom(
    TranslateModule.forRoot({
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
      defaultLanguage: DEFAULT_LANGUAGE.split('-')[0],
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CustomMissingTranslationHandler,
      },
    })
  ),
  {
    provide: APP_INITIALIZER,
    useFactory: (langService: LanguageInitializerService) => () => langService.initializeLanguage(),
    deps: [LanguageInitializerService],
    multi: true,
  },
];
