import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { catchError, Observable, retry, switchMap } from 'rxjs';
import { LocalizationService } from '../services/localization.service';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult, SilentRequest } from '@azure/msal-browser';
import { UserService } from '../services/user.service';
import { environment } from 'src/environments/environment';
import { Store } from '@ngxs/store';
import { SetToken } from '@boels-state/actions/user.action';
import { UserStoreService } from '@boels-core/services/user.store.service';
import { DestroyReferenceDirective } from '@boels-shared/directives/destroy-reference.directive';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable()
export class TokenInterceptor extends DestroyReferenceDirective implements HttpInterceptor {
  private readonly locale: string = '';
  private token: string = '';

  constructor(
    private readonly localizationService: LocalizationService,
    private readonly msalService: MsalService,
    private readonly userService: UserService,
    private readonly store: Store,
    private readonly userStoreService: UserStoreService
  ) {
    super();
    this.locale = this.localizationService.localization;

    this.userStoreService.token$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((token) => (this.token = token));
  }

  public intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    if (request.method === 'JSONP' || request.headers.get('skip')) {
      return next.handle(request);
    }
    const request$ = new Observable<HttpRequest<T>>((observer) => {
      observer.next(this.addToken(request, this.token));
      observer.complete();
    });

    return request$.pipe(
      switchMap((req) => {
        return next.handle(req);
      }),
      catchError(async (error: HttpErrorResponse) => {
        if (error.status === 401) {
          const result: AuthenticationResult = await this.refreshToken();
          this.setToken(result.accessToken);
          this.addToken(request, result.accessToken);
        }
        throw error;
      }),
      retry(1),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 || (error.status === 400 && error.error.error === 'invalid_grant')) {
          this.userService.logoutUser();
        }
        throw error;
      })
    );
  }

  private addToken<T>(req: HttpRequest<T>, token: string): HttpRequest<T> {
    return req.clone({
      setHeaders: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Authorization: `Bearer ${token}`,
        'Accept-Language': this.locale,
      },
    });
  }

  private async refreshToken(): Promise<AuthenticationResult> {
    const account: AccountInfo = this.msalService.instance.getActiveAccount();
    const tokenRequest: SilentRequest = {
      scopes: ['openid', environment.msalConfig.instance.auth.clientId],
      account,
    };

    return this.msalService.instance.acquireTokenSilent(tokenRequest);
  }

  private setToken(token: string): void {
    this.store.dispatch(new SetToken(token));
  }
}
