<div class="menu-container">
  <nav class="main-menu">
    <ul class="nav-list">
      @for (node of mainNodes; track node) {
        <li class="nav-item">
          <a [routerLink]="node.uri" routerLinkActive="is-active">
            <img src="assets/images/{{ node.icon }}.svg" [alt]="'menu.' + node.icon | translate" loading="lazy" />
            <span class="title content-ellipsis">{{ 'menu.' + node.icon | translate }}</span>
          </a>
        </li>
      }
      <li class="nav-item mobile-navigation__new-order">
        <a
          [title]="'orders.startNewOrder' | translate"
          href="{{ EXTERNAL_URL.BOELS.HIRE(localizationService.localization) }}"
          [qa]="'mobile-navigation__new-order'"
        >
          <mat-icon svgIcon="new-order"></mat-icon>
          <span class="title">{{ 'orders.startNewOrder' | translate }}</span>
        </a>
      </li>
    </ul>
  </nav>
</div>
