import { inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { combineLatest, filter, map, take } from 'rxjs';
import { LocalizationService } from 'src/app/core/services/localization.service';
import { PageViewEvent } from '../models/events/pageView.event';
import { UserStoreService } from './user.store.service';
import { Store } from '@ngxs/store';
import { UserState } from '@boels-state/state/user.state';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DestroyReferenceDirective } from '@boels-shared/directives/destroy-reference.directive';
import { isAppRunningLocally } from '@boels-shared/utils/helper.utils';

@Injectable()
export class PageEventsService extends DestroyReferenceDirective {
  private readonly router = inject(Router);
  private readonly gtmService = inject(GoogleTagManagerService);
  private readonly localizationService = inject(LocalizationService);
  private readonly userStoreService = inject(UserStoreService);
  private readonly jwtHelper = inject(JwtHelperService);
  private readonly store = inject(Store);

  public init(): void {
    this.setPageViewEvents();
  }

  private setPageViewEvents(): void {
    this.router.events.forEach((item) => {
      // Tracking is disabled for localhost domain, so for DEVs no tracking.
      if (item instanceof NavigationEnd && !isAppRunningLocally()) {
        const pageName: string = item.url.startsWith('/') ? item.url.slice(1) || 'dashboard' : item.url;

        combineLatest([
          this.store.select(UserState.selectUserToken).pipe(
            map((token) => this.jwtHelper.decodeToken(token)?.sub),
            filter((result) => result)
          ),
          this.userStoreService.activeDebtorNumbers$,
        ])
          .pipe(
            filter((debtors) => !!debtors),
            take(1),
            takeUntilDestroyed(this.destroyRef)
          )
          .subscribe(([userId, debtorNumbers]) => {
            const gtmTag: PageViewEvent = {
              event: 'virtual_page_view',
              debtor_id: debtorNumbers[0],
              user_id: userId,
              context: {
                locale: {
                  country: this.localizationService.localization.split('-')[1],
                  language: this.localizationService.localization.split('-')[0],
                },
                page: {
                  name: pageName,
                  type: undefined,
                },
              },
            };

            this.gtmService.pushTag(gtmTag);
          });
      }
    });
  }
}
