import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AppRoutesUrisConst } from '@boels-shared/constants/app-routes-uris.const';
import { Observable } from 'rxjs';
import { DestroyReferenceDirective } from 'src/app/shared/directives/destroy-reference.directive';
import { StorageKey } from 'src/app/shared/enums/storage.enum';
import { baseBoelsUrl } from '../../../../environments/environment';
import { Link } from '../../models/link.model';
import { Debtor } from '../../models/user.model';
import { LocalizationService } from '../../services/localization.service';
import { UserStoreService } from '../../services/user.store.service';
import { EXTERNAL_URL } from '@boels-core/config/external-url.config';
import { TranslateModule } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';
import { QaHooksDirective } from '@boels-experience/core';
import { RouterLinkActive, RouterLink } from '@angular/router';

@Component({
  selector: 'app-mobile-navigation',
  templateUrl: './mobile-navigation.component.html',
  styleUrls: ['./mobile-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterLinkActive, RouterLink, QaHooksDirective, MatIcon, TranslateModule],
})
export class MobileNavigationComponent extends DestroyReferenceDirective {
  public readonly localizationService = inject(LocalizationService);
  public readonly userStoreService = inject(UserStoreService);

  public AppRoutesUrisConst = AppRoutesUrisConst;
  public nodes: Array<Link> = [
    { title: 'Overview', icon: 'overview', uri: AppRoutesUrisConst.Dashboard() },
    { title: 'My Products', icon: 'my-products', uri: AppRoutesUrisConst.Products() },
    { title: 'Contracts', icon: 'contracts', uri: AppRoutesUrisConst.Contracts() },
    { title: 'Invoices', icon: 'invoices', uri: AppRoutesUrisConst.Invoices() },
  ];
  public mainNodes: Array<Link> = this.nodes.slice(0, 4);
  public selectedLanguage!: string;
  public accounts$: Observable<ReadonlyArray<Debtor>> = this.userStoreService.accounts$;
  public activeAccounts$: Observable<Array<Debtor>> = this.userStoreService.activeAccounts$;

  protected readonly baseBoelsUrl = baseBoelsUrl;
  protected readonly EXTERNAL_URL = EXTERNAL_URL;

  constructor() {
    super();

    this.localizationService.country$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.selectedLanguage = localStorage.getItem(StorageKey.COUNTRY_LANGUAGE) || '';
    });
  }
}
