{
    "name": "my-boels",
    "version": "dev-20241115.1-80bb604",
    "scripts": {
        "ng": "ng",
        "start": "ng serve",
        "start-no-reload": "ng serve --no-live-reload",
        "start-ios": "ng serve --host local-my.boels.com --ssl",
        "build": "ng build",
        "watch": "ng build --watch --configuration development",
        "test": "ng test --code-coverage",
        "test:no-watch": "ng test --watch=false --code-coverage --browsers ChromeHeadless",
        "prettier": "prettier \"src/**/*.{ts,js,scss,html}\" --write --loglevel=error",
        "lint": "ng lint --fix",
        "prepare": "husky install",
        "localazy:upload": "npx localazy upload",
        "localazy:download": "npx localazy download",
        "localazy:languages": "npx localazy languages",
        "localazy:tag:list": "npx localazy tag list",
        "build:experience": "ng build boels-experience",
        "watch:experience": "ng build boels-experience --watch",
        "pack:experience": "cd dist/boels-experience && npm pack",
        "storybook": "ng run MyBoels:storybook",
        "build-storybook": "ng run MyBoels:build-storybook"
    },
    "private": true,
    "dependencies": {
        "@angular/animations": "18.1.4",
        "@angular/cdk": "^18.1.4",
        "@angular/common": "^18.1.4",
        "@angular/compiler": "^18.1.4",
        "@angular/core": "^18.1.4",
        "@angular/forms": "^18.1.4",
        "@angular/google-maps": "^18.1.4",
        "@angular/material": "^18.1.4",
        "@angular/platform-browser": "^18.1.4",
        "@angular/platform-browser-dynamic": "^18.1.4",
        "@angular/router": "^18.1.4",
        "@auth0/angular-jwt": "^5.2.0",
        "@azure/msal-angular": "3.0.9",
        "@azure/msal-browser": "3.6.0",
        "@fortawesome/angular-fontawesome": "^0.15.0",
        "@fortawesome/free-brands-svg-icons": "^6.5.2",
        "@fortawesome/free-solid-svg-icons": "^6.5.2",
        "@material/checkbox": "^15.0.0-canary.a246a4439.0",
        "@material/dialog": "^15.0.0-canary.a246a4439.0",
        "@material/tab": "^15.0.0-canary.a246a4439.0",
        "@material/textfield": "^15.0.0-canary.a246a4439.0",
        "@messageformat/core": "^3.3.0",
        "@ngx-translate/core": "^15.0.0",
        "@ngx-translate/http-loader": "^8.0.0",
        "@ngxs/logger-plugin": "^18.1.1",
        "@ngxs/storage-plugin": "^18.1.1",
        "@ngxs/store": "^18.1.1",
        "@sentry/angular": "^8.28.0",
        "angular-google-tag-manager": "^1.9.0",
        "file-saver": "^2.0.5",
        "fullscreen-polyfill": "^1.0.4",
        "highcharts": "^11.2.0",
        "libphonenumber-js": "^1.10.51",
        "lottie-web": "^5.12.2",
        "ngx-lottie": "^12.0.0",
        "ngx-progressbar": "^11.1.0",
        "rxjs": "~7.8.1",
        "tslib": "^2.6.2",
        "zone.js": "~0.14.2"
    },
    "devDependencies": {
        "@angular-devkit/build-angular": "^18.1.4",
        "@angular-eslint/builder": "^18.1.0",
        "@angular-eslint/eslint-plugin": "^18.1.0",
        "@angular-eslint/eslint-plugin-template": "^18.1.0",
        "@angular-eslint/schematics": "^18.1.0",
        "@angular-eslint/template-parser": "^18.1.0",
        "@angular/cli": "^18.1.4",
        "@angular/compiler-cli": "^18.1.4",
        "@chromatic-com/storybook": "^1.9.0",
        "@compodoc/compodoc": "^1.1.25",
        "@localazy/cli": "^1.7.5",
        "@schematics/angular": "^17.0.7",
        "@storybook/addon-a11y": "^8.3.5",
        "@storybook/addon-docs": "^8.2.9",
        "@storybook/addon-essentials": "^8.2.9",
        "@storybook/addon-interactions": "^8.2.9",
        "@storybook/addon-links": "^8.2.9",
        "@storybook/addon-onboarding": "^8.2.9",
        "@storybook/angular": "^8.2.9",
        "@storybook/blocks": "^8.2.9",
        "@storybook/test": "^8.2.9",
        "@types/file-saver": "^2.0.7",
        "@types/jasmine": "~5.1.4",
        "@types/node": "^20.10.4",
        "@typescript-eslint/eslint-plugin": "^6.14.0",
        "@typescript-eslint/parser": "^6.14.0",
        "eslint": "^8.55.0",
        "eslint-config-prettier": "^9.1.0",
        "eslint-plugin-prettier": "^5.0.1",
        "eslint-plugin-storybook": "^0.8.0",
        "husky": "^8.0.3",
        "jasmine-core": "~5.1.1",
        "jira-prepare-commit-msg": "^1.7.2",
        "karma": "~6.4.2",
        "karma-chrome-launcher": "~3.2.0",
        "karma-coverage": "~2.2.1",
        "karma-jasmine": "~5.1.0",
        "karma-jasmine-html-reporter": "~2.1.0",
        "ng-packagr": "^18.1.0",
        "prettier": "^3.1.1",
        "pretty-quick": "^3.1.3",
        "storybook": "^8.2.9",
        "typescript": "5.5.4"
    },
    "lint-staged": {
        "*.ts": "eslint --cache --fix",
        "*.{ts,scss,html,md}": "prettier --write"
    },
    "jira-prepare-commit-msg": {
        "messagePattern": "$J: $M",
        "jiraTicketPattern": "([A-Z]+-\\d+)",
        "commentChar": "#",
        "isConventionalCommit": false,
        "allowEmptyCommitMessage": false,
        "gitRoot": ""
    },
    "overrides": {
        "angular-google-tag-manager": {
            "@angular/animations": "^18.1.1",
            "@angular/common": "^18.1.1",
            "@angular/compiler": "^18.1.1",
            "@angular/core": "^18.1.1",
            "@angular/forms": "^18.1.1"
        }
    },
    "scarfSettings": {
        "enabled": false
    }
}
