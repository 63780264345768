import * as Sentry from '@sentry/angular';
import { APP_INITIALIZER, EnvironmentProviders, ErrorHandler, Injector, Provider } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import { CookieBotService } from '@boels-core/services/cookie-bot.service';
import { CookieBotConsentTypeEnum } from '@boels-core/enums/cookie-bot.enum';
import { filter, take } from 'rxjs';
import type { Client } from '@sentry/types';

const initSentry = (): Client | undefined =>
  Sentry.init({
    ...environment.sentry, // dsn and other data
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  });

export const provideSentry = (): Array<Provider | EnvironmentProviders> => [
  {
    provide: ErrorHandler,
    useValue: Sentry.createErrorHandler(),
  },
  {
    provide: Sentry.TraceService,
    deps: [Router],
  },
  {
    provide: APP_INITIALIZER,
    useFactory: (injector: Injector) => () => {
      const cookieBotService = injector.get(CookieBotService);

      // Wait for consent to initialize Sentry
      cookieBotService
        .getCookieBotConsentStateByProperty(CookieBotConsentTypeEnum.STATISTICS)
        .pipe(
          filter((consent) => !!consent),
          take(1)
        )
        .subscribe(() => {
          initSentry();
        });
    },
    deps: [Injector, Sentry.TraceService],
    multi: true,
  },
];
